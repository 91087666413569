

import { CloseButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./AprModal.scss"

function AprModal({closeModal}) {
  return (
      
   <div className='Aprcontainer'>
         <div class='backdrop'></div>
    <Modal.Dialog >
        
        <CloseButton onClick={()=>{closeModal(false)}}/>
      <Modal.Header >
        <Modal.Title>How is APR for Syrup Pools calculated?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Syrup Pool APR = Annualized rewards  / User funds staked in Syrup Pool  * 100 <br/>
        The APR fluctuates as more CAKE is staked by users, and as the price of CAKE, and the reward token, vary.</p>
      </Modal.Body>

      <Modal.Footer>
        {/* <Button variant="secondary">Close</Button>
        <Button variant="primary">Save changes</Button> */}
      </Modal.Footer>
      
    </Modal.Dialog>
    </div>
  );
}

export default AprModal;
