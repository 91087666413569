import React, {useState} from 'react'
import "./ReferralModal.scss";
import Modal from 'react-modal';
import Card from '../Card/Card';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import closeBtn from "../../assets/images/ionic-md-close.svg";
import { saveReferralLink} from "../../redux/actions";
import { useDispatch,useSelector } from "react-redux";
import Web3 from "web3";
import { toast } from "../Toast/Toast";
function ReferralModal({isOpen, handleClose}) {
  const savedLink = useSelector((state) => state.persist.referralLink);

  const dispatch = useDispatch();
  const [link,setLink]= useState('');
  const handleChange = (event) => {
    setLink(event.target.value);
    console.log(link);
  }
  const handleSubmit = (e) => {
    dispatch(saveReferralLink(link));
    handleClose();
    if (Web3.utils.isAddress(link)){
      return toast.success("Successful");
    } else{
      return toast.error("Not a valid address");
    }
    
  };
  
  return (
    <>
    {/* <div className="container_wrap container_Ref">
    <div className="container ref"> */}
      <Modal isOpen={isOpen}>
            <Card className="profile_modal_ref">
              <div className="col modal_headerStyle">
                <div className="row modal_headerStyle__rowA lessMargin_bottom align-items-baseline">
                  <div className=" modal_headerStyle__rowA_colLeft">
                    <h2>Paste your Referral Link</h2>
                    <p>
                      Make sure to open this page with a Browser that can
                      connect to your wallet.
                    </p>
                  </div>
                  <div className="col modal_headerStyle__rowA_colRight">
                    <Link to="#" onClick={handleClose}>
                      <img src={closeBtn} alt="icon" className="cross_btn" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row modal_headerStyle__rowB">
                <div className="wallet_list_ref">
                  <input
                  className='input_ref'
                    placeholder="Paste your Referral Link here …"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                  />
                  <Button
                    type="button"
                    className="sbmt_btn"
                    onClick={handleSubmit}
                  >
                    Submit
                    
                  </Button>
                  
                </div>
              </div>
            </Card>
          </Modal>
          {/* </div>
          </div> */}
    </>
  )
}

export default ReferralModal