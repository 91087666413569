import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import eifiicon from "../../../assets/images/eifi_coin.svg";
import ethicon from "../../../assets/images/eth_coin.svg";
import VolumUP from "../../../assets/images/volume_icon_sorting.svg";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import Pagination from "../../../Components/Pagination/Pagination";
import "./Analytics.scss";

const AnalyticPools = () => {
  return (
    <div className="Analyticin">
      <Row>
        <Col>
          <div className="yourWatchlist">
            <h2 className="theme_title">Your Watchlist</h2>
            <CustomSearch placeholder="Saved pools will appear here" />
          </div>
          <h2 className="theme_title">All Pools</h2>
          <div className="table_area">
            <Table responsive hover className="dataTable big_table mb-0">
              <thead>
                <tr>
                  <th className="sr_no colorYellow">#</th>
                  <th className="colorYellow">Pools</th>
                  <th>
                    Volume 24H{" "}
                    {/* <span className="th_down">
                      <img src={VolumUP} />
                    </span> */}
                  </th>
                  <th>
                    Volume 7D{" "}
                    {/* <span className="th_down">
                      <img src={VolumUP} />
                    </span> */}
                  </th>
                  <th>LP Reward Fees 24H</th>
                  <th>LP Reward APR </th>
                  <th>Liquidity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$427.72</td>
                  <td>$123.12</td>
                  <td>$426.72</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$1.94B</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$27.72</td>
                  <td>$137.21</td>
                  <td>$26.72</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$787.77M</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$51.02</td>
                  <td>$137.21</td>
                  <td>$136.72</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$187.77M</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$427.72</td>
                  <td>$137.21</td>
                  <td>$426.72</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$1.94B</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$27.72</td>
                  <td>$162.92</td>
                  <td>$26.72</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$787.77M</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$53.72</td>
                  <td>$211.172</td>
                  <td>$116.172</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$897.7B</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Pagination />
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticPools;
