import React from 'react'
import { Card } from 'react-bootstrap'
import './AnalyticCard.scss'

const AnalyticCard = props => {
    return (
        <Card>
            <Card.Body className="analytic_card">
                <div className="analytic_detail">
                    <h4 className="blue_title">{props.title}</h4>    
                    <div className="activ">{props.totalvalue}</div>
                    <div className="date">{props.date}</div>
                </div>
                <div className="graph">
                    <img src={props.graph} />
                </div>
            </Card.Body>
        </Card>       
    )
}

export default AnalyticCard
