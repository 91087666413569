import React from "react";
import { Table } from "react-bootstrap";
import Forwdicon from "../../../../assets/images/externalLink.svg";
import VolumUP from "../../../../assets/images/volume_icon_sorting.svg";
import Pagination from "../../../../Components/Pagination/Pagination";
import "./TransactionsTab.scss";

const All = () => {
  const TransactionsData = [
    {
      action: "Swap WBNB ANCHOR",
      TotalValue: "$427.72",
      TokenAmount: "0.49 WBNB",
      Volume: "3,067.285 ANCHOR",
      Account: "0x03...7c2d",
      Time: "2 Hours",
    },
    {
      action: "Swap WBNB ANCHOR",
      TotalValue: "$427.72",
      TokenAmount: "0.49 WBNB",
      Volume: "3,067.285 ANCHOR",
      Account: "0x03...7c2d",
      Time: "2 Hours",
    },
    {
      action: "Swap WBNB ANCHOR",
      TotalValue: "$427.72",
      TokenAmount: "0.49 WBNB",
      Volume: "3,067.285 ANCHOR",
      Account: "0x03...7c2d",
      Time: "2 Hours",
    },
    {
      action: "Swap WBNB ANCHOR",
      TotalValue: "$427.72",
      TokenAmount: "0.49 WBNB",
      Volume: "3,067.285 ANCHOR",
      Account: "0x03...7c2d",
      Time: "2 Hours",
    },
    {
      action: "Swap WBNB ANCHOR",
      TotalValue: "$427.72",
      TokenAmount: "0.49 WBNB",
      Volume: "3,067.285 ANCHOR",
      Account: "0x03...7c2d",
      Time: "2 Hours",
    },
  ];

  return (
    <div className="AnalyticTab trans_in my-2">
      <div className="table_area">
        <Table responsive hover className="dataTable big_table mb-0">
          <thead>
            <tr>
              <th className="colorYellow paddLeft">Action</th>
              <th>Total Value</th>
              <th>
                Token Amount
                {/* <span className="th_down">
                  <img src={VolumUP} />
                </span> */}
              </th>
              <th>Volume 7D </th>
              <th>Account</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {TransactionsData.map((item, index) => (
              <tr>
                <td>
                  {item.action}
                  <span className="forward_link">
                    <img src={Forwdicon} />
                  </span>
                </td>
                <td>{item.TotalValue}</td>
                <td>{item.TokenAmount}</td>
                <td>{item.Volume}</td>
                <td className="actionLink">
                  <a href="">
                    {item.Account}
                    <span className="forward_link">
                      <img src={Forwdicon} />
                    </span>
                  </a>
                </td>
                <td>{item.Time}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination />
    </div>
  );
};
export default All;
