import React from "react";
import "./Pagination.scss";

const Pagination = () => {
  return (
    <div className="customPagination">
      <ul>
        <li className="active">1</li>
        <li>2</li>
        <li>3</li>
        <li>4</li>
      </ul>
    </div>
  );
};

export default Pagination;
