import { useEffect, useState } from 'react';
import './CardPool.scss';
import Card from '../Card/Card';
import Button from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ContractServices } from '../../services/ContractServices';
import { syrupPoolTokensCake, blockExecutePerDay } from '../../assets/tokens';
import { Fragment } from 'react';
import { toast } from '../Toast/Toast';
import { addTransaction, startLoading, stopLoading } from '../../redux/actions';
import { BigNumber } from "bignumber.js";
import { FarmService } from '../../services/FarmService';
import { ExchangeService } from '../../services/ExchangeService';
import { addCommas } from '../../constant';
import { USDT, CAKE_USDT } from '../../assets/tokens';
import AprModal from '../../Pages/Public/Pools/AprModal';

const CardPool = (props) => {
  const { farm: { syrupPoolAddress, rewardTokenSymbol, pid, userInfo, rewardEarned, rewardToken, days }, stakeHandle, harvestOnClick } = props;
  const isUserConnected = useSelector(state => state.persist.isUserConnected);
  const [openModal, setOpenModal]= useState(false);
  const [balance, setBalance] = useState(0);
  const [stakeAmounts, setStakeAmounts] = useState({ amount: 0, rewards: 0 });
  const [showApproveButton, setShowApproveButton] = useState(true);
  const [approvalConfirmation, setApprovalConfirmation] = useState(false);
  const [dollarValue, setAnchorDollarValue] = useState(0.01);
  const [apr, setApr] = useState(0);


  const dispatch = useDispatch();


  useEffect(() => {
    // getAnchorDollarValue();
    init();
  }, [isUserConnected]);
  const getAnchorDollarValue = async () => {
    // const reserves = await ExchangeService.getReserves(ANCHOR_BUSD_LP);
    // let val = reserves[1] / reserves[0];
    // val = val || 0;
    // setAnchorDollarValue(val.toFixed(3));
  }

  const getTotalRewardsToDistribute = async (rewardToken) => {
    const lp = await ExchangeService.getPair(rewardToken, USDT);
     console.log("LPPPPPPPPP", lp);
    const rewardTokenDecimal = await ContractServices.getDecimals(rewardToken);
    if(lp != "0x0000000000000000000000000000000000000000"){
    const t1 = await ExchangeService.getTokenZero(lp);
    const t2 = await ExchangeService.getTokenOne(lp);
    const reserves = await ExchangeService.getReserves(lp);
          console.log(t1);
          if(t1 != "0x0000000000000000000000000000000000000000"){
    if (t1.toLowerCase() === USDT.toLowerCase()) {
      const rewardReserve = reserves[1] / 10 ** rewardTokenDecimal;
      const usdtReserve = reserves[0] / 10 ** 6;
      return rewardReserve / usdtReserve;
    }
  }

    const rewardReserve = reserves[0] / 10 ** rewardTokenDecimal;
    const usdtReserve = reserves[1] / 10 ** 6;
    return rewardReserve / usdtReserve;
  }
  }
  const getCakeUsdtValue = async () => {
    const reserves = await ExchangeService.getReserves(CAKE_USDT);
    return (reserves[1] / 10 ** 18) / (reserves[0] / 10 ** 6);
  }

  const init = async () => {
    if (syrupPoolAddress && isUserConnected) {
      const allowance = await ContractServices.allowanceToken(syrupPoolTokensCake, syrupPoolAddress, isUserConnected);
      let check = true;
      if (BigNumber(allowance).isGreaterThanOrEqualTo(BigNumber(2 * 255 - 1))) {
        setShowApproveButton(false);
        check = false;
      }

      let balance = await ContractServices.getTokenBalance(syrupPoolTokensCake, isUserConnected);
      if (balance > 0.00001) {
        balance -= 0.00001;
      }
      setBalance(balance);

      let rewardsPerBlock = await FarmService.rewardsPerBlock(syrupPoolAddress);
      const totalRewards = await getTotalRewardsToDistribute(rewardToken);
      const dailyDistribution = totalRewards / days;
      const annualisedRewards = dailyDistribution * 365;
      const cakeusdtValue = await getCakeUsdtValue();
      const poolStakedCake = await FarmService.syrupPoolTokenStaked(syrupPoolAddress);
      const valueOfCakeStakedByUsersInPool = poolStakedCake * cakeusdtValue;
      console.log(valueOfCakeStakedByUsersInPool);


      const apr = poolStakedCake > 0 ? (annualisedRewards / valueOfCakeStakedByUsersInPool.toFixed(2)) * 100 : 0;

      setApr(apr.toFixed(2));
    }
  };
  const handleTokenApproval = async () => {
    const acc = await ContractServices.getDefaultAccount();
    if (acc && acc.toLowerCase() !== isUserConnected.toLowerCase()) {
      return toast.error('Wallet address doesn`t match!');
    }
    if (approvalConfirmation) {
      return toast.info('Token approval is processing');
    }
    // (2*256 - 1);
    const value = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

    try {
      dispatch(startLoading());
      setApprovalConfirmation(true);
      const r = await ContractServices.approveToken(isUserConnected, value, syrupPoolAddress, syrupPoolTokensCake);
      if (r) {
        let data = {
          message: `Approve LP Token`,
          tx: r.transactionHash
        };
        dispatch(addTransaction(data));
        setApprovalConfirmation(false);

      }
      dispatch(stopLoading());
    } catch (err) {
      setApprovalConfirmation(false);
      dispatch(stopLoading());
      toast.error('Approval Transaction Reverted!');
    }
  }
  const beforeStake = async (type) => {
    if (isUserConnected) {
      let bal = 0;
      if (type === 'deposit') {
        bal = balance;
      }
      if (type === 'withdraw') {
        bal = stakeAmounts.amount;
      }
      stakeHandle({ pid, userInfo, syrupPoolAddress, balance: bal }, type);
    } else {
      return toast.error('Connect wallet first!');
    }
  }

  const earnedDollarValue = (dollarValue, rewards) => {
    let fixedAfterDecimal = Number((dollarValue * rewards)).toFixed(3);
    let res = addCommas(fixedAfterDecimal);
    return res;
  }
  return (
    <>
    <Card className="farm_card pool-box box_col">
      <div className="col">
        <ul className="farm_planInfo">
          <li>

            <strong>Stake </strong>
            <strong>Cake </strong>
          </li>
          <li>
            <strong>Earn: </strong>
            <strong>{rewardTokenSymbol}</strong>
          </li>
          <li>
            <strong>APR: </strong>
            <strong><span className="token-icon" onClick={() =>{setOpenModal(true)}}>

              <svg viewBox="0 0 25 24" color="text" width="20px" xmlns="http://www.w3.org/2000/svg" className="sc-bdfBwQ dhaIlc">
              <path d="M19.2 3H5.19995C4.09995 3 3.19995 3.9 3.19995 5V19C3.19995 20.1 4.09995 21 5.19995 21H19.2C20.3 21 21.2 20.1 21.2 19V5C21.2 3.9 20.3 3 19.2 3ZM19.2 19H5.19995V5H19.2V19Z" fill="#1FC7D4"></path>
              <path d="M11.45 7.72021H6.44995V9.22022H11.45V7.72021Z" fill="#1FC7D4"></path>
              <path d="M18.2 15.75H13.2V17.25H18.2V15.75Z" fill="#1FC7D4"></path>
              <path d="M18.2 13.25H13.2V14.75H18.2V13.25Z" fill="#1FC7D4"></path>
              <path d="M8.19995 18H9.69995V16H11.7V14.5H9.69995V12.5H8.19995V14.5H6.19995V16H8.19995V18Z" fill="#1FC7D4"></path>
              <path d="M14.29 10.95L15.7 9.54L17.11 10.95L18.17 9.89L16.76 8.47L18.17 7.06L17.11 6L15.7 7.41L14.29 6L13.23 7.06L14.64 8.47L13.23 9.89L14.29 10.95Z" fill="#1FC7D4"></path>
              </svg></span>{apr} %</strong>
          </li>

          <li>
            <strong>Cake STAKED: </strong>
            <strong>{addCommas(userInfo.amount / 10 ** 18)}</strong>
          </li>
        </ul>
      </div>
      <div className="box-flex-col">
        <div className="col cardFarm__earned">
          {addCommas((rewardEarned / 10 ** 18).toFixed())}
          
        </div>
        <div className="col cardFarm__buttonBlock">
          <Button
            onClick={() => harvestOnClick(pid, syrupPoolAddress)}
            className="btn_cardFarm_style "
            disabled={rewardEarned > 0 ? false : true}
          >
            Harvest
          </Button>
        </div>
      </div>
      <div className="col cardFarm__buttonBlock">
        <div className="staked">
          <div className="cardFarm__buttonBlock__value">
            <div className="col cardFarm__buttonBlock__increaseBtns">
              {isUserConnected ?
                <Fragment>
                  {showApproveButton ?
                    <Button type="button" className=""
                      onClick={() => handleTokenApproval()}
                    >
                      Approve Contract
                    </Button>
                    :
                    <Button type="button"
                      onClick={() => beforeStake('deposit')}
                    >
                      Stake
                    </Button>}
                </Fragment>
                :
                <Fragment>
                  <Button type="button" className=""
                    onClick={() => toast.error('Connect to wallet first!')}
                  >
                    Unlock Wallet
                  </Button>
                </Fragment>
              }
            </div>
          </div>
        </div>
      </div>

    </Card>
          {openModal && <AprModal closeModal={setOpenModal}/>};
          </>

  )

}


export default CardPool;