import React, { useState } from "react";
import Button from "../../../Components/Button/Button";
import "./Login.scss";
import "../ReferralPage/ReferralPage.scss"

import "../../../Components/Header/Header.scss"
import logo from "../../../assets/images/anchorswap-header-logo.svg";
import WalletList from "../../../Components/Header/WalletList";

import ReferralModal from "../../../Components/ReferralModal/ReferralModal";
import { useSelector } from "react-redux";
import Web3 from "web3";
import {toast} from "../../../Components/Toast/Toast"
function Login() {

  const [walletShow, setWalletShow] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const savedLink = useSelector((state) => state.persist.referralLink);

  
  return (
    <div className="container_wrap container_Ref">
      <div className="container ref">
        <img src={logo} alt="ref_logo" />
        <h2>
          Connect your wallet to AnchorSwap and start earning up to{" "}
          <span>160% APR</span> in our Yield Farms!
        </h2>
        <p>You need to connect your wallet in order to use this App.</p>
        <div className="lockbox">
          <Button
            type="button"
            className=""
            onClick={() => setWalletShow(!walletShow)}
          >
            Connect Wallet
          </Button>
          <Button
            type="button"
            className="ref_login_btn"
            onClick={() => setModalIsOpen(true)}
          >
            Login with Referral Link
          </Button>
          <ReferralModal isOpen={modalIsOpen} handleClose={() => setModalIsOpen(!modalIsOpen)} />
          <p>
            <a>Learn more about AnchorFinance</a>
          </p>
          <p>
            Your ref link is : {`${savedLink && savedLink.substr(
              savedLink.length - 42
            )}`}
          </p>
        </div>
        {" "}
        {walletShow && <WalletList isWalletShow={setWalletShow} />}
      </div>
    </div>
  );
}

export default Login;
