import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { HOME_ROUTE } from "../../constant"
import { logout } from "../../redux/actions";

const AuthGuard = ({ component: Component, ...rest }) => {
  const { isUserConnected } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        isUserConnected ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${HOME_ROUTE}`,
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    isUserConnected: state.persist.isUserConnected
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
