import React from "react";
import { Container, Row, Col, Table, Breadcrumb } from "react-bootstrap";
import Tabs from "../../../Components/CustomTabs/Tabs";
import TabPane from "../../../Components/CustomTabs/TabPane";
import eifiicon from "../../../assets/images/eifi_coin.svg";
import ethicon from "../../../assets/images/eth_coin.svg";
import POLKADOT from "../../../assets/images/token_icons/POLKADOT.svg";
import usdt_icon from "../../../assets/images/usdt_icon.svg";
import BUSD from "../../../assets/images/BUSD-Token.svg";
import ANCHOR from "../../../assets/images/token_icons/ANCHOR.svg";
import BTCB from "../../../assets/images/BTCB-Token.svg";
import WBNB from "../../../assets/images/WBNB.svg";
import PriceUP from "../../../assets/images/price_up_icon.svg";
import PriceDown from "../../../assets/images/price_down_icon.svg";
import VolumUP from "../../../assets/images/volume_icon_sorting.svg";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import Pagination from "../../../Components/Pagination/Pagination";
import bnb from "../../../assets/images/bnbb.png";
import "./Analytics.scss";
import Button from "../../../Components/Button/Button";
import arrow from "../../../assets/images/right-arrow.svg";
import darrow from "../../../assets/images/externalLink.svg";
import up_arrow from "../../../assets/images/price_up_icon.svg";

const AnalyticToken = () => {
  const tabledata = [
    {
      serial: "1",
      img: WBNB,
      tokenName: "WBNB",
      price: "$428.33",
      priceChange: "0.69%",
      volu24: "$52.22M",
      Liq: "$115.66M",
    },
    {
      serial: "2",
      img: usdt_icon,
      tokenName: "USDT",
      price: "$1.00",
      priceChange: "0.02%",
      volu24: "$39.22M",
      Liq: "$100.16M",
    },
    {
      serial: "3",
      img: BUSD,
      tokenName: "BUSD",
      price: "$0.99",
      priceChange: "0.01%",
      volu24: "$22.92M",
      Liq: "$55.66M",
    },
    {
      serial: "4",
      img: BTCB,
      tokenName: "BTCB",
      price: "$44,071.33",
      priceChange: "3.34%",
      volu24: "$19.02M",
      Liq: "$28.36M",
    },
    {
      serial: "5",
      img: ethicon,
      tokenName: "ETH",
      price: "$3,133",
      priceChange: "1.19%",
      volu24: "$52.22M",
      Liq: "$105.66M",
    },
    {
      serial: "6",
      img: ANCHOR,
      tokenName: "Anchor",
      price: "$0.0758",
      priceChange: "14.51%",
      volu24: "$22.96M",
      Liq: "$55.96M",
    },
    {
      serial: "7",
      img: POLKADOT,
      tokenName: "Polkadot",
      price: "$0,533",
      priceChange: "0.69%",
      volu24: "$52.22M",
      Liq: "$0.224M",
    },
  ];
  return (
    <div className="Analyticin">
      <div className="breadcrumb_sec">
        <Breadcrumb>
          <Breadcrumb.Item href="#">Analytics</Breadcrumb.Item>
          <img src={arrow} alt="" />
          <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
            Tokens
          </Breadcrumb.Item>
          <img src={arrow} alt="" />
          <Breadcrumb.Item active>WBNB</Breadcrumb.Item>
        </Breadcrumb>
        <p>View on BscScan <span><img src={darrow} alt="" className="darrow" /></span></p>
      </div>
      <Row className="bnb_row">
        <div className="bnb_token">
          <img src={bnb} alt="bnb_coin" />
          <h2>
            WBNB <span>(WBNB)</span>
          </h2>
        </div>
        <div className="bnb_btns">
          <Button>Add Liquidity</Button>
          <Button>Trade</Button>
        </div>
      </Row>
      <div className="value_token">
        <span>$430.63</span>
        <span><img src={up_arrow} alt=""/>1.76%</span>
      </div>
      <Row className="token_row">
        <div className="col_sec">
          <div className="left_design">
            <div className="info_design">
              <h3>Liquidity</h3>
              <h2>$84.70M</h2>
              <h5 className="token_value">24.89%</h5>
            </div>
            <div className="hl"></div>
            <div className="info_design">
              <h3>Volume 24H</h3>
              <h2>$41.40M</h2>
              <h5 className="token_value">13.36%</h5>
            </div>
            <div className="hl"></div>
            <div className="info_design">
              <h3>Volume 7D</h3>
              <h2>$293.29M</h2>
            </div>
            <div className="hl"></div>
            <div className="info_design">
              <h3>Transactions 24H</h3>
              <h2>36.04K</h2>
            </div>
          </div>
        </div>
        <div className="col_sec1">
          <div className="right_design">
            <Tabs className="token_tabs">
              <TabPane name="Volume" className="token_tab" key="1"></TabPane>
              <TabPane name="Liquidity" className="token_tab" key="2"></TabPane>
              <TabPane name="Price" className="token_tab" key="3"></TabPane>
            </Tabs>
          </div>
          <div className="right_token_graph">
            <h2>$6225977.18</h2>
            <h4>Feb 16, 2022</h4>
          </div>
        </div>
      </Row>

      <Row>
        <Col>
          <div className="yourWatchlist">
            <h2 className="theme_title">Your Watchlist</h2>
            <CustomSearch placeholder="Saved pools will appear here" />
          </div>
          <h2 className="theme_title">Top Pools</h2>
          <div className="table_area">
            <Table responsive hover className="dataTable mb-0">
              <thead>
                <tr>
                  <th className="sr_no colorYellow">#</th>
                  <th className="colorYellow">Name</th>
                  <th>Price</th>
                  <th>Price Change</th>
                  <th>
                    Volume 24H{" "}
                    <span className="th_down">
                      <img src={VolumUP} />
                    </span>
                  </th>
                  <th>Liquidity</th>
                </tr>
              </thead>
              <tbody>
                {tabledata.map((item, index) => (
                  <tr>
                    <td>{item.serial}</td>
                    <td className="icon_id">
                      <span>
                        <img src={item.img} alt="icon" />
                      </span>
                      {item.tokenName}
                    </td>
                    <td>{item.price}</td>
                    <td>
                      <span className="up">
                        <img src={PriceUP} /> {item.priceChange}
                      </span>
                    </td>
                    <td>{item.volu24}</td>
                    <td>{item.Liq}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination />
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticToken;
