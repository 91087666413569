import React from "react";
import { Container } from "react-bootstrap";
import Card from "../../../Components/Card/Card";
import "./ReferralPage.scss";
import youtube from "../../../assets/images/youtube.svg";
import telegram from "../../../assets/images/telegram.svg";
import twitter from "../../../assets/images/twitter.svg";
import forward from "../../../assets/images/forward.png";

const ReferralPage = () => {
  return (
    <>
      <div className="container_wrap container_Refpage">
        <div className="refpage">
          <h1>Referral Program</h1>
          <p>
            Earn additional Anchor Tokens by inviting your friends to the
            project!
          </p>
        </div>

        <div className="row referral">
          <div className="head_content">
            <h4>Your Referral link</h4>
            <p>https://anchorswap.app/r/…..5052 </p>
          </div>
          <div className="head_content">
            <h4>Total Referrals</h4>
            <p>50</p>
          </div>
          <div className="head_content">
            <h4>Total Referral Commissions</h4>
            <p>85,561.05 ANCHOR</p>
          </div>
        </div>
        <Container>
          <div className="how_it_works_sec">
            <h2>How it Works</h2>
            <ul>
              <li>
                <div className="work_area">
                  <div className="work_img">
                    <h4>Copy your Referral Link</h4>
                    <p>
                      First you need to copy your Referral Link and send it to
                      your friend.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="work_area">
                  <div className="work_img">
                    <h4>Make sure your friend connects correctly</h4>
                    <p>
                      Your friend needs to paste your Referral Link into a
                      browser that can connect with the application. For example
                      open MetaMask App, go to Settings and open the Browser.
                      Paste the Referral Link, open the page and connect the
                      wallet.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="work_area">
                  <div className="work_img">
                    <h4>Activate the Referral</h4>
                    <p>
                      To activate the Referral your friend needs to buy his
                      first Anchor Tokens. Go to Trade - Exchange and buy Anchor
                      Tokens by swapping any other tokens.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="work_area">
                  <div className="work_img">
                    <h4>Staking of Anchor Tokens</h4>
                    <p>
                      Your referral needs to stake his Anchor Tokens into one of
                      the Yield Farming products such as Anchor Pool for
                      example.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="work_area">
                  <div className="work_img">
                    <h4>Done</h4>
                    <p>
                      Every time your friend Harvest his earned tokens on the
                      Yield Farms, you will get your Commission automatically
                      and immediately send to your wallet. Congratulations!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Container>
        <div className="media_Wrap">
          <div class="row">
            <div className="social_sec">
              <span className="social_head">
                <h2>AnchorFinance</h2>
                <p>Visit our website for more information</p>
              </span>
              <span className="social_content">
                <a href="">
                  <img src={forward} alt="" />
                </a>
              </span>
            </div>
            <div className="social_sec">
              <span className="social_head">
                <h2>Youtube</h2>
                <p>Trailer, Amas, project ipdates and more</p>
              </span>
              <span className="social_content">
                <a href="">
                  <img src={youtube} alt="" />
                </a>
              </span>
            </div>
            <div className="social_sec">
              <span className="social_head">
                <h2>Telegram</h2>
                <p>Any Question? We care about you.</p>
              </span>
              <span className="social_content">
                <a href="">
                  <img src={telegram} alt="" />
                </a>
              </span>
            </div>
            <div className="social_sec">
              <span className="social_head">
                <h2>Twitter</h2>
                <p>Follow Anchor and never miss on anything</p>
              </span>
              <span className="social_content">
                <a href="">
                  <img src={twitter} alt="" />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralPage;
