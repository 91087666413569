import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Login from "../../Pages/Public/Login/Login";
import { HOME_ROUTE } from "../../constant";

const PublicRoutes = () => {

  return (
    <>
      <Switch>
        <Route path={HOME_ROUTE} component={Login} exact={true} />
      </Switch>
    </>
  );
};

export default withRouter(PublicRoutes);
