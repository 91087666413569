import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AUTH_TOKEN_KEY, HOME_ROUTE } from "../../constant";
import { getToken, removeToken } from "../../Helpers/storageHelper";

const NoGuard = ({ component: Component, ...rest }) => {

  const { isUserConnected } = rest;
  return (
    <Route
      {...rest}
      render={props =>
        !isUserConnected ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${HOME_ROUTE}auth/home`,
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isUserFirstTimeLogin: state.persist.isUserFirstTimeLogin

  };
};

export default connect(mapStateToProps, null)(NoGuard);
