import React from "react";
import { Form } from "react-bootstrap";
import "./CustomSearch.scss";

const CustomSearch = ({ className, placeholder }) => {
  return (
    <div className={`customSearch_style ${className}`}>
      <form>
        <div className="formGroup">
          <input
            className="customInput"
            type="text"
            placeholder={placeholder}
          />
        </div>
      </form>
    </div>
  );
};

export default CustomSearch;
