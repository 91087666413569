import React from 'react'
import { Button, NavItem } from 'react-bootstrap';
import WBNB from "../assets/images/WBNB.svg";
import PriceUP from "../assets/images/price_up_icon.svg"
import eifiicon from "../assets/images/eifi_coin.svg";
import ethicon from "../assets/images/eth_coin.svg";
import POLKADOT from "../assets/images/token_icons/POLKADOT.svg";
import usdt_icon from "../assets/images/usdt_icon.svg";
import BUSD from "../assets/images/BUSD-Token.svg";
import ANCHOR from "../assets/images/token_icons/ANCHOR.svg";
import BTCB from "../assets/images/BTCB-Token.svg";
import { HOME_ROUTE } from '../constant';
import { useSelector } from 'react-redux';

function AllAnalytics(props) {
  const anchorValue = useSelector((state) => state.persist.anchorValue);

  const tabledata = [
    {
      serial: "1",
      img: WBNB,
      tokenName: "WBNB",
      price: "$428.33",
      priceChange: "0.69%",
      volu24: "$52.22M",
      Liq: "$115.66M",
    },
    {
      serial: "4",
      img: BTCB,
      tokenName: "BTCB",
      price: "$44,071.33",
      priceChange: "3.34%",
      volu24: "$19.02M",
      Liq: "$28.36M",
    },
    {
      serial: "5",
      img: ethicon,
      tokenName: "ETH",
      price: "$3,133",
      priceChange: "1.19%",
      volu24: "$52.22M",
      Liq: "$105.66M",
    },
    {
      serial: "6",
      img: ANCHOR,
      tokenName: "Anchor",
      price: `${anchorValue}`,
      priceChange: "14.51%",
      volu24: "$22.96M",
      Liq: "$55.96M",
    },
    
  ];

  const TopPools = [
    {
      serial: "1",
      img: WBNB,
      tokenName: "WBNB",
      price: "$428.33",
      priceChange: "0.69%",
      volu24: "$15.41M",
      volu7: "$94.27M",

      Liq: "$38.66M",
    },
  ];
  return (
    <div>
              <div className="row token_row">

      {tabledata.map((item) => (
        <div className="col token_info">
        <img src={item.img} alt="" />
        <div className="d-block">
          <span className="d-flex">
            <h6>{item.tokenName}</h6>
            <p className="up">{item.priceChange}</p>
          </span>
          <h3>{item.price}</h3>
        </div>
      </div>
      
      ))}
      <div className="col token_info">
        <Button onClick={()=> window.open(`${HOME_ROUTE}auth/analytics`, "")} >All analytics</Button>
      </div>
      </div>
    </div>
  )
}

export default AllAnalytics;