import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import { Tabs, Tab } from "react-bootstrap";
import AnalyticOverview from "./AnalyticOverview";
import AnalyticPools from "./AnalyticPools";
import AnalyticToken from "./AnalyticToken";
import { rootName } from "../../../constant";
import Tabs from "../../../Components/CustomTabs/Tabs";
import TabPane from "../../../Components/CustomTabs/TabPane";
import "./Analytics.scss";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";

const Analytics = (props) => {
  const {
    match: { params },
    history,
  } = props;
  const { tab } = params;
  const handleTab = (data) => {
    history.push(`${rootName}/Analytics/${data}`);
  };

  return (
    <div className="AnalyticTab container_wrap">
      <Container>
        <Row>
          <Col>
            <CustomSearch
              className={`analyticSearch`}
              placeholder="Search pools or tokens"
            />
            <Tabs>
              <TabPane name="Overview" key="1">
                <AnalyticOverview />
              </TabPane>
              <TabPane name="Pools" key="2">
                <AnalyticPools />
              </TabPane>
              <TabPane name="Tokens" key="3">
                <AnalyticToken />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Analytics;
