import "./CardHome.scss";

const CardHome = (props) => {
  return (
    <div className={`cardHome_style ${props.className}`}>
      {props.topTitle ? (
        <p className="cardHome_toptitle">{props.topTitle}</p>
      ) : (
        ""
      )}
      <img src={props.img} alt="icon" />
      <h2 className={`cardHome_titleStyle ${props.classTitle}`}>
        {props.title}
      </h2>
      <h3 className={`cardHome_subtitle ${props.subTitle}`}>
        {props.subtitle}
      </h3>
      <p className="cardHome_content">{props.content}</p>
      {props.children}
    </div>
  );
};

export default CardHome;
