import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import AnalyticCard from "../../../Components/AnalyticCard/AnalyticCard";
import Graph from "../../../assets/images/newGraph.png";
import eifiicon from "../../../assets/images/eifi_coin.svg";
import ethicon from "../../../assets/images/eth_coin.svg";
import POLKADOT from "../../../assets/images/token_icons/POLKADOT.svg";
import usdt_icon from "../../../assets/images/usdt_icon.svg";
import BUSD from "../../../assets/images/BUSD-Token.svg";
import ANCHOR from "../../../assets/images/token_icons/ANCHOR.svg";
import BTCB from "../../../assets/images/BTCB-Token.svg";
import WBNB from "../../../assets/images/WBNB.svg";
import VolumUP from "../../../assets/images/volume_icon_sorting.svg";
import PriceUP from "../../../assets/images/price_up_icon.svg";
import PriceDown from "../../../assets/images/price_down_icon.svg";
import All from "./TransactionsTab/All";
import Swaps from "./TransactionsTab/Swaps";
import Adds from "./TransactionsTab/Adds";
import Removes from "./TransactionsTab/Removes";
import "./Analytics.scss";
import Pagination from "../../../Components/Pagination/Pagination";
import Tabs from "../../../Components/CustomTabs/Tabs";
import TabPane from "../../../Components/CustomTabs/TabPane";

const AnalyticOverview = (props) => {
  const tabledata = [
    {
      serial: "1",
      img: WBNB,
      tokenName: "WBNB",
      price: "$428.33",
      priceChange: "0.69%",
      volu24: "$52.22M",
      Liq: "$115.66M",
    },
    {
      serial: "2",
      img: usdt_icon,
      tokenName: "USDT",
      price: "$1.00",
      priceChange: "0.02%",
      volu24: "$39.22M",
      Liq: "$100.16M",
    },
    {
      serial: "3",
      img: BUSD,
      tokenName: "BUSD",
      price: "$0.99",
      priceChange: "0.01%",
      volu24: "$22.92M",
      Liq: "$55.66M",
    },
    {
      serial: "4",
      img: BTCB,
      tokenName: "BTCB",
      price: "$44,071.33",
      priceChange: "3.34%",
      volu24: "$19.02M",
      Liq: "$28.36M",
    },
    {
      serial: "5",
      img: ethicon,
      tokenName: "ETH",
      price: "$3,133",
      priceChange: "1.19%",
      volu24: "$52.22M",
      Liq: "$105.66M",
    },
    {
      serial: "6",
      img: ANCHOR,
      tokenName: "Anchor",
      price: "$0.0758",
      priceChange: "14.51%",
      volu24: "$22.96M",
      Liq: "$55.96M",
    },
    {
      serial: "7",
      img: POLKADOT,
      tokenName: "Polkadot",
      price: "$0,533",
      priceChange: "0.69%",
      volu24: "$52.22M",
      Liq: "$0.224M",
    },
  ];

  const TopPools = [
    {
      serial: "1",
      img: WBNB,
      tokenName: "WBNB",
      price: "$428.33",
      priceChange: "0.69%",
      volu24: "$15.41M",
      volu7: "$94.27M",

      Liq: "$38.66M",
    },
  ];

  return (
    <div className="Analyticin">
      <div className="analyticChart_top">
        <div className="priceRow">
          <h2 className="theme_title">AnchorSwap Info & Analytics</h2>
          <div className="anchorData">
            <span>
              ANCHOR Price:<span className="textWhite">$ 0.074</span>
            </span>
            <span>
              Transactions (24H):<span className="textWhite">31516</span>
            </span>
            <span>
              Pairs:<span className="textWhite">18</span>
            </span>
            <span>
              Holders:<span className="textWhite">2008</span>
            </span>
            <span>
              Fees:<span className="textWhite">$28438,258</span>
            </span>
          </div>
        </div>

        <Row className="chartRow">
          <Col>
            <AnalyticCard
              title={`Liquidity`}
              totalvalue={`11,049,996.69`}
              date={`Feb 8, 2022`}
              graph={Graph}
            />
          </Col>
          <Col>
            <AnalyticCard
              title={`24H Volume`}
              totalvalue={`$24 583`}
              date={`Feb 8, 2022`}
              graph={Graph}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <h2 className="theme_title">Top Tokens</h2>
          <div className="table_area">
            <Table responsive hover className="dataTable mb-0">
              <thead>
                <tr>
                  <th className="sr_no colorYellow">#</th>
                  <th className="colorYellow">Name</th>
                  <th>Price</th>
                  <th>Price Change</th>
                  <th>
                    Volume 24H{" "}
                    {/* <span className="th_down">
                      <img src={VolumUP} />
                    </span> */}
                  </th>
                  <th>Liquidity</th>
                </tr>
              </thead>
              <tbody>
                {tabledata.map((item, idx) => (
                  <tr>
                    <td>{item.serial}</td>
                    <td className="icon_id">
                      <span>
                        <img src={item.img} alt="icon" />
                      </span>
                      {item.tokenName}
                    </td>
                    <td>{item.price}</td>
                    <td>
                      <span className="up">
                        <img src={PriceUP} /> {item.priceChange}
                      </span>
                    </td>
                    <td>{item.volu24}</td>
                    <td>{item.Liq}</td>
                  </tr>
                ))}

                {/* <tr style={{ textAlign: "center" }}>
                  <td colSpan="7">No records found.</td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <Pagination />
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="theme_title">Top Pools</h2>
          <div className="table_area">
            <Table responsive hover className="dataTable big_table mb-0">
              <thead>
                <tr>
                  <th className="sr_no colorYellow">#</th>
                  <th className="colorYellow">Name</th>
                  <th>
                    Volume 24H{" "}
                    {/* <span className="th_down">
                      <img src={VolumUP} />
                    </span> */}
                  </th>
                  <th>
                    Volume 7D{" "}
                    {/* <span className="th_down">
                      <img src={VolumUP} />
                    </span> */}
                  </th>
                  <th>LP Reward Fees 24H</th>
                  <th>LP Reward APR </th>
                  <th>Liquidity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$427.72</td>
                  <td>$123.12</td>
                  <td>$426.72</td>
                  <td>
                    <span className="up">+ 20.06%</span>
                  </td>
                  <td>$1.94B</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$27.72</td>
                  <td>$137.21</td>
                  <td>$26.72</td>
                  <td>
                    <span className="up">+ 21.02%</span>
                  </td>
                  <td>$787.77M</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$51.02</td>
                  <td>$137.21</td>
                  <td>$136.72</td>
                  <td>
                    <span className="up">+ 0.123%</span>
                  </td>
                  <td>$187.77M</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$427.72</td>
                  <td>$137.21</td>
                  <td>$426.72</td>
                  <td>
                    <span className="up">+ 113.15%</span>
                  </td>
                  <td>$1.94B</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$27.72</td>
                  <td>$162.92</td>
                  <td>$26.72</td>
                  <td>
                    {" "}
                    <span className="up">+ 0.06%</span>
                  </td>
                  <td>$787.77M</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td className="icon_id pool">
                    <span>
                      <img src={eifiicon} />
                      <img className="backface" src={ethicon} />
                    </span>{" "}
                    EiFi-weth
                  </td>
                  <td>$53.72</td>
                  <td>$211.172</td>
                  <td>$116.172</td>
                  <td>
                    {" "}
                    <span className="up">+ 09.16%</span>
                  </td>
                  <td>$897.7B</td>
                </tr>
                {/* <tr style={{ textAlign: "center" }}>
                  <td colSpan="7">No records found.</td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <Pagination />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="theme_title">Transactions</h2>
          <div className="trans_tab_view">
            <Tabs>
              <TabPane name="All" key="1">
                <All />
              </TabPane>
              <TabPane name="Swaps" key="2">
                <Swaps />
              </TabPane>
              <TabPane name="Adds" key="3">
                <Adds />
              </TabPane>
              <TabPane name="Removes" key="4">
                <Removes />
              </TabPane>
            </Tabs>

            {/* <Tabs defaultActiveKey="first" id="trans_tab" variant="pills">
              <Tab eventKey="first" title="All">
                <All />
              </Tab>
              <Tab eventKey="Swaps" title="Swaps">
                <Swaps />
              </Tab>
              <Tab eventKey="Adds" title="Adds">
                <Adds />
              </Tab>
              <Tab eventKey="Removes" title="Removes">
                <Removes />
              </Tab>
            </Tabs> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticOverview;
