import React from "react";
import { Table } from "react-bootstrap";
import Forwdicon from "../../../../assets/images/externalLink.svg";
import VolumUP from "../../../../assets/images/volume_icon_sorting.svg";
import Pagination from '../../../../Components/Pagination/Pagination'
import "./TransactionsTab.scss";

const Swaps = () => {
  return (
    <div className="AnalyticTab trans_in my-2">
      <div className="table_area">
        <Table responsive hover className="dataTable big_table mb-0">
          <thead>
            <tr>
              <th className="colorYellow paddLeft">Action</th>
              <th>Total Value</th>
              <th>
                Token Amount
                {/* <span className="th_down">
                  <img src={VolumUP} />
                </span> */}
              </th>
              <th>Volume</th>
              <th>Swaps</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Swap WBNB ANCHOR{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>$427.72</td>
              <td>0.49 WBNB</td>
              <td>3,067.285 ANCHOR</td>
              <td className="actionLink">
                0x03...7c2d{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>2 Hours</td>
            </tr>
            <tr>
            <td>
                Swap WBNB ANCHOR{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>$527.72</td>
              <td>0.49 WBNB</td>
              <td>3,067.285 ANCHOR</td>
              <td className="actionLink">
                0x03...7c2d{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>3 Hours</td>
            </tr>
            <tr>
              <td>
                Swap WBNB ANCHOR{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>$527.72</td>
              <td>0.49 WBNB</td>
              <td>3,067.285 ANCHOR</td>
              <td className="actionLink">
                0x03...7c2d{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>3 Hours</td>
            </tr>
            <tr>
              <td>
                Swap WBNB ANCHOR{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>$427.72</td>
              <td>0.49 WBNB</td>
              <td>3,067.285 ANCHOR</td>
              <td className="actionLink">
                0x03...7c2d{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>2 Hours</td>
            </tr>
            <tr>
              <td>
                Swap WBNB ANCHOR{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>$527.72</td>
              <td>0.49 WBNB</td>
              <td>3,067.285 ANCHOR</td>
              <td className="actionLink">
                0x03...7c2d{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>3 Hours</td>
            </tr>
            <tr>
              <td>
                Swap WBNB ANCHOR{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>$527.72</td>
              <td>0.49 WBNB</td>
              <td>3,067.285 ANCHOR</td>
              <td className="actionLink">
                0x03...7c2d{" "}
                <span>
                  <img src={Forwdicon} />
                </span>
              </td>
              <td>3 Hours</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Pagination />
    </div>
  );
};
export default Swaps;
