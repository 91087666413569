import React, { useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import "./Referrals.scss";
import { useSelector } from "react-redux";
import { ReferralsServices } from "../../../services/ReferralsServices";
import { toast } from "../../../Components/Toast/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import logo from "../../../assets/images/anchorswap-header-logo.svg";
import copyIcon from "../../../assets/images/icon_copyAddress.png";
import Card from "../../../Components/Card/Card";
import closeBtn from "../../../assets/images/ionic-md-close.svg";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";

function Referrals() {
  const isUserConnected = useSelector((state) => state.persist.isUserConnected);
  useEffect(() => {
    // init();
    return () => {};
  }, []);

  const [referralCount, setReferralCount] = useState("");
  const [isOpen, setModal] = useState(false);
  const [referralIncome, setReferralIncome] = useState("");
  const [walletShow, setWalletShow] = useState(false);

  const init = async () => {
    const count = await ReferralsServices.getReferralCount(isUserConnected);
    const income = await ReferralsServices.getReferralCommissions(
      isUserConnected
    );
    setReferralCount(count);
    setReferralIncome(income);
  };

  const connectCall = () => {
    isUserConnected ? setModal(!isOpen) : setWalletShow(true);
  };
  return (
    <div className="container_wrap container_Ref">
      <div className="container ref">
        <img src={logo} alt="ref_logo" />
        <h2>
          Connect your wallet to AnchorSwap and start earning up to{" "}
          <span>160% APR</span> in our Yield Farms!
        </h2>
        <p>You need to connect your wallet in order to use this App.</p>
        {isUserConnected ? (
          <div className="Unlockbox">
            <div className="UnlockboxTop">
              <div className="UnlockboxIn">
                <h3>Total Referrals</h3>
                <p>{referralCount}</p>
              </div>
              <div className="UnlockboxIn">
                <h3>Total Referral Commissions</h3>
                <p>{referralIncome} Anchor</p>
              </div>
            </div>
            <div className="UnlockboxIn">
              <h3>
                Your Referral Link{" "}
                {/* <Link to="#" className="copy">
                  Copy
                </Link> */}
              </h3>
              <CopyToClipboard
                text={`${window.location.origin}/r/${isUserConnected}`}
                onCopy={() => toast.success("Copied!")}
              >
                <a className="text-left" href="javascript:void(0);">
                  <a
                    href={`${window.location.origin}/r/${isUserConnected}`}
                    rel="noreferrer"
                  >{`${window.location.origin}/r/${isUserConnected}`}</a>
                  <img className="copy-icon" src={copyIcon} alt="copy" />
                </a>
              </CopyToClipboard>
            </div>
          </div>
        ) : (
          <div className="lockbox">
            <Button
              type="button"
              className=""
              onClick={() => toast.error("Connect wallet first!")}
            >
              Connect Wallet
            </Button>
            <Button
              type="button"
              className="ref_login_btn"
              onClick={() => connectCall()}
            >
              Login with Referral Link
            </Button>
            {walletShow ? (
              <Card className="profile_modal_ref">
                <div className="col modal_headerStyle">
                  <div className="row modal_headerStyle__rowA lessMargin_bottom">
                    <div className=" modal_headerStyle__rowA_colLeft">
                      <h2>Paste your Referral Link</h2>
                      <p>
                        Make sure to open this page with a Browser that can
                        connect to your wallet.
                      </p>
                    </div>
                    <div className="col modal_headerStyle__rowA_colRight">
                      <Link to="#" onClick={() => setWalletShow(false)}>
                        <img src={closeBtn} alt="icon" className="cross_btn" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row modal_headerStyle__rowB">
                  <div className="wallet_list_ref">
                    <FormControl
                      placeholder="Paste your Referral Link here …"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <Button
                      type="button"
                      className="sbmt_btn"
                      onClick={() => toast.error("Connect wallet first!")}
                    >
                      <a href="./ReferralPage">Submit</a>
                    </Button>
                  </div>
                </div>
              </Card>
            ) : (
              ""
            )}
            <p>
              <a>Learn more about AnchorFinance</a>
            </p>
          </div>
        )}{" "}
      </div>
    </div>
  );
}

export default Referrals;
