import React, { useState,useEffect } from 'react'
import { useSelector } from 'react-redux';
import rightarrow from "../assets/images/chevron-right.svg";
import { addCommas } from '../constant'
import { FarmService } from './../services/FarmService';
import {TOKEN_LIST} from '../assets/tokens'
import { ExchangeService } from './../services/ExchangeService'
import { ContractServices } from '../services/ContractServices';
import { BNB_BUSD_LP , ANCHOR_BUSD_LP} from '../assets/tokens';
import { WETH } from '../assets/tokens';

function TopFarms(props) {
    const { farm: { poolInfo }, } = props;
   const [roi, setROI] = useState({ allocPoint: 0, totalAllcationPoint: 0, anchorPerBlock: 0, anchorPrice: 0, liquidity: 0, lpWorth: 0 });

    const [apr, setApr] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [lpTokenDetails, setLpTokenDetails] = useState(null);
    const isUserConnected = useSelector(state => state.persist.isUserConnected);

    const init = async () => {
        if (poolInfo) {
            const { lpToken } = poolInfo;
            if (lpToken) {
                const lpTokenDetailsTemp = await FarmService.getLpTokenDetails(lpToken);
                setLpTokenDetails(lpTokenDetailsTemp);
                const liquidity = await handleLiquidity(lpToken);
                setLiquidity(liquidity);
               const a = await calculateAPR(Number(poolInfo.allocPoint), lpToken, liquidity);
                lpTokenDetailsTemp.apr = a;
               setApr(a);
               
                
            }
        }
    }

    
    const handleLiquidity = async (pairAddress) => {

        if (pairAddress != "0x0000000000000000000000000000000000000000") {

            const tokenZero = await ExchangeService.getTokenZero(pairAddress);
            const tokenOne = await ExchangeService.getTokenOne(pairAddress);
            const reserve = await ExchangeService.getReserves(pairAddress);

            const tokenZeroPairUSDT = await ExchangeService.getPair(tokenZero, TOKEN_LIST[2].address);
            const tokenOnePairUSDT = await ExchangeService.getPair(tokenOne, TOKEN_LIST[2].address);

            const tokenZeroPairBNB = await ExchangeService.getPair(tokenZero, WETH);
            const tokenOnePairBNB = await ExchangeService.getPair(tokenOne, WETH);

            const decimalZero = await ContractServices.getDecimals(tokenZero);
            const decimalOne = await ContractServices.getDecimals(tokenOne);
            // const decimalPair = await ContractServices.getDecimals(pairAddress);

            let priceA = 0;
            let priceB = 0;

            if (tokenZero.toLowerCase() == TOKEN_LIST[2].address.toLowerCase()) {
                priceA = 1;
            } else if (tokenZero.toLowerCase() == WETH.toLowerCase()) {
                priceA = await calPrice(BNB_BUSD_LP);
            }

            if (tokenOne.toLowerCase() == TOKEN_LIST[2].address.toLowerCase()) {
                priceB = 1;
            } else if (tokenOne.toLowerCase() == WETH.toLowerCase()) {
                priceB = await calPrice(BNB_BUSD_LP);
            }

            if (priceA == 0) {
                if (tokenZeroPairUSDT != "0x0000000000000000000000000000000000000000") {
                    priceA = await calPrice(tokenZeroPairUSDT);
                } else if (tokenZeroPairBNB != "0x0000000000000000000000000000000000000000") {
                    priceA = await calPrice(tokenZeroPairBNB);
                } else {
                    priceA = 0;
                }
            }

            if (priceB == 0) {
                if (tokenOnePairUSDT != "0x0000000000000000000000000000000000000000") {
                    priceB = await calPrice(tokenOnePairUSDT);
                } else if (tokenOnePairBNB != "0x0000000000000000000000000000000000000000") {
                    priceB = await calPrice(tokenOnePairBNB);
                } else {
                    priceB = 0;
                }
            }

            const totalSupply = await ExchangeService.getTotalSupply(pairAddress);
            const tokenStaked = await ExchangeService.getTokenStaked(pairAddress);

            const liquidity = ((((reserve[0] / (10 ** decimalZero)) * priceA) + ((reserve[1] / (10 ** decimalOne)) * priceB)) / (totalSupply)) * (tokenStaked);

            return liquidity;
        }
        return 0;
    }

    const calPrice = async (pairAddress) => {

        let price = 0;

        if (pairAddress == "0x0000000000000000000000000000000000000000") {
            return 0;
        }

        // console.log("pairAddresspairAddress", pairAddress);
        const tokenZero = await ExchangeService.getTokenZero(pairAddress);
        const tokenOne = await ExchangeService.getTokenOne(pairAddress);
        const reserve = await ExchangeService.getReserves(pairAddress);

        const decimalZero = await ContractServices.getDecimals(tokenZero);
        const decimalOne = await ContractServices.getDecimals(tokenOne);

        // console.log(tokenZero, TOKEN_LIST[2].address);

        if (tokenZero.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
            return price = ((reserve[0] * (10 ** decimalOne)) / (reserve[1] * (10 ** decimalZero)));
        }

        if (tokenOne.toLowerCase() === TOKEN_LIST[2].address.toLowerCase()) {
            return price = ((reserve[1] * (10 ** decimalZero)) / (reserve[0] * (10 ** decimalOne)));
        }

        let priceBNBToUSD = calPrice(BNB_BUSD_LP);//replace with BNB-USD pair

        if (tokenZero.toLowerCase() === WETH.toLowerCase()) {
            price = ((reserve[0] * (10 ** decimalOne)) / (reserve[1] * (10 ** decimalZero)));
            return (price * priceBNBToUSD);
        }

        if (tokenOne.toLowerCase() === WETH.toLowerCase()) {
            price = ((reserve[1] * (10 ** decimalZero)) / (reserve[0] * (10 ** decimalOne)));
            return (price * priceBNBToUSD);
        }

    }

    const calculateAPR = async (allocPoint, lpToken, lpWorth) => {
        const anchorPrice = await calPrice(ANCHOR_BUSD_LP);
        const totalAllcationPoint = Number(await FarmService.totalAllocationPoint());
        const anchorPerBlock = Number(await FarmService.pantherPerBlock());

        //need to calculate usd price.
        const liquidity = await handleLiquidity(lpToken);
        // console.log("liquidity: ", liquidity);
        if (liquidity != 0) {
            const apr = ((allocPoint / totalAllcationPoint) * ((anchorPerBlock / 10 ** 18) * 28800 * 365 * 100 * anchorPrice)) / liquidity;
            setROI({ allocPoint, totalAllcationPoint, anchorPerBlock, anchorPrice, liquidity, lpWorth });

            return apr.toFixed(2);
        }

        return 0;
    }
    const handleIcon = (symbol) => {
        if (symbol != undefined) {
            const tokenObj = TOKEN_LIST.find(
                (d) => d.symbol.toLowerCase() === symbol.toLowerCase()
            );
            return tokenObj != undefined && tokenObj.icon;
        }

    }
    const handleDefaultIcon = (symbol) => {
        if (symbol != undefined) {
            const tokenObj = TOKEN_LIST.find(
                (d) => d.symbol.toLowerCase() === symbol.toLowerCase()
            );
            let index = tokenObj != undefined && tokenObj.icon.lastIndexOf("/") + 1;
            let filename = tokenObj != undefined && tokenObj.icon.substr(index);
            return filename == 'default.60b90c93.svg' ? 'farm-coin' : '';
        }
    }

    useEffect(() => {
        init();
    
    }, [isUserConnected]);
    return (
        <div className="col farm_info">
            <div className="row farm_row">
                <span className="eth_data">
                    <img src={handleIcon(lpTokenDetails?.symbol0)} alt="" className="farm_img_one" />
                    <img src={handleIcon(lpTokenDetails?.symbol1)} alt="" className="farm_img_two" />
                </span>
                <span className="sec">
                    <h2>{lpTokenDetails?.lpTokenName}</h2>
                    <p>Liquidity ${addCommas(Number(liquidity.toFixed(2)))}</p>
                </span>
            </div>
            <div className="farm_detail">
                <h2>
                    APR <span>{addCommas(apr)}%</span>
                </h2>
                <p>
                    <span className="arr">
                        Start farming
                        <img src={rightarrow} alt="" />
                    </span>
                </p>
            </div>
            
        </div>
        
    );
}

export default TopFarms